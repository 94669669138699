import React, { useEffect } from "react";
import { Router } from "@reach/router";
import useLocalizedLocationPath from "../../../hooks/use-localized-location-path";
import { ProductListItemType } from "../../../types/airgraft";
import ProtectedRoute from "../../user/components/ProtectedRoute";
import ExpressDeliveryCheckoutNavigation from "./components/Navigation";
import ExpressDeliveryCheckoutAuth from "./containers/AuthPage";
import ExpressDeliveryCheckout from "./containers/CheckoutPage";
import CheckoutLayout from "./components/Layout";
import useExpressDeliveryCart from "../../../hooks/use-express-delivery-cart";

type Props = {
  allProducts: ReadonlyArray<ProductListItemType>;
};

export default function ExpressDeliveryCheckoutApp({ allProducts }: Props) {
  const getLocalizedPath = useLocalizedLocationPath();

  // On load: Auto-close cart
  const cart = useExpressDeliveryCart();
  useEffect(() => {
    cart.toggleCartPanel(false);
  }, []);

  return (
    <>
      <ExpressDeliveryCheckoutNavigation allProducts={allProducts} />
      <Router basepath={getLocalizedPath("/delivery/checkout")}>
        <ExpressDeliveryCheckoutAuth path="/sign-in" />
        <ExpressDeliveryCheckoutAuth path="/register" />
        <ProtectedRoute
          default
          redirectTo={getLocalizedPath("/delivery/checkout/register")}
          component={ExpressDeliveryCheckout}
          allProducts={allProducts}
          renderBeforeUserLoaded={<CheckoutLayout showLoading />} // Wait until user object is loaded to render Checkout page
        />
      </Router>
    </>
  );
}
