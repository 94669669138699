import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Hidden,
  styled
} from "@material-ui/core";
import { useLocation } from "@reach/router";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import MultistepNavigationLabel from "../../../../components/multistep-navigation/components/NavigationLabel";
import {
  getNavigationIndexFromPathname,
  getNavigationSteps
} from "./Navigation";

const CustomContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

type Props = {
  children?: JSX.Element | JSX.Element[];
  showLoading?: boolean;
  ContainerProps?: Omit<ContainerProps, "children">;
  BoxProps?: Omit<BoxProps, "children">;
};

export default function CheckoutLayout({
  children,
  showLoading,
  ContainerProps,
  BoxProps
}: Props) {
  const { pathname } = useLocation();
  const navigationSteps = getNavigationSteps();
  const navigationIndex = getNavigationIndexFromPathname(pathname);

  return (
    <CustomContainer maxWidth="sm" {...(ContainerProps || {})}>
      {showLoading && (
        <Box mt={{ xs: 2, sm: 4 }}>
          <Skeleton width="50%" height="36px" animation="wave" />
          <Skeleton width="100%" height="32px" animation="wave" />
          <Skeleton width="80%" height="32px" animation="wave" />
        </Box>
      )}
      {!showLoading && (
        <Box
          mt={{ xs: 2, sm: 4, md: 6 }}
          mb={{ xs: 4, sm: 8 }}
          {...(BoxProps || {})}
        >
          {/* Desktop navigation label */}
          {navigationIndex !== null && (
            <Hidden xsDown implementation="css">
              <Box mb={3}>
                <MultistepNavigationLabel
                  steps={navigationSteps}
                  activeStepIndex={navigationIndex}
                />
              </Box>
            </Hidden>
          )}

          {children}
        </Box>
      )}
    </CustomContainer>
  );
}
