import { Box, CircularProgress, Hidden, Typography } from "@material-ui/core";
import React from "react";
import { useLockBodyScroll } from "react-use";
import { useProgressiveLoadingText } from "./useProgressiveLoadingText";
import FadeInUp from "../animations/FadeInUp";
import useTranslate from "../../hooks/use-translate";

type Props = {
  texts: string[];
  timings: number[];
};

export default function LoadingOverlay({ texts, timings }: Props) {
  useLockBodyScroll();
  const dynamicText = useProgressiveLoadingText(timings, texts);

  return (
    <Box
      position="fixed"
      width="100%"
      height="100%"
      bgcolor="rgba(238, 235, 229, 0.85)"
      style={{
        backdropFilter: "blur(3px)",
        WebkitBackdropFilter: "blur(3px)"
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      zIndex={3000}
      top={0}
      left={0}
      paddingX={1}
    >
      {/* Mobile */}
      <Hidden smUp>
        <CircularProgress size="48px" color="primary" disableShrink />

        <Box mt={3}>
          <FadeInUp key={dynamicText} duration={0.25}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              {dynamicText}
            </Typography>
          </FadeInUp>
        </Box>
      </Hidden>

      {/* Desktop */}
      <Hidden xsDown>
        <CircularProgress size="48px" color="primary" disableShrink />
        <Box mt={3} maxWidth="800px">
          <FadeInUp key={dynamicText}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              {dynamicText}
            </Typography>
          </FadeInUp>
        </Box>
      </Hidden>
    </Box>
  );
}
