import { Box, useTheme } from "@material-ui/core";
import { useLocation } from "@reach/router";
import React from "react";
import MultiStepNavigation from "../../../../components/multistep-navigation";
import { ProductListItemType } from "../../../../types/airgraft";
import NavigationCart from "./NavigationCart";

export function getNavigationSteps() {
  const steps = ["Account", "Review order"];
  return steps;
}

/**
 * Calculate what is the curent steps index based on current path
 */
export function getNavigationIndexFromPathname(pathname) {
  const parts = pathname.split("/").filter(p => p !== "");
  const step = parts[parts.length - 1];
  const steps = getNavigationSteps();

  if (step === "sign-in" || step === "register") {
    return steps.indexOf("Account");
  }

  return steps.indexOf("Review order");
}

type Props = {
  allProducts?: ReadonlyArray<ProductListItemType>;
};

export default function ExpressDeliveryCheckoutNavigation({
  allProducts
}: Props) {
  const theme = useTheme();

  const { pathname } = useLocation();
  const navigationIndex = getNavigationIndexFromPathname(pathname);

  if (navigationIndex === null || navigationIndex === -1) {
    return null;
  }

  return (
    <>
      <Box
        position="fixed"
        top={0}
        left={0}
        zIndex={theme.zIndex.appBar + 1}
        width="100%"
        height="var(--header-height)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="background.default"
      >
        <MultiStepNavigation
          activeStepIndex={navigationIndex}
          steps={getNavigationSteps()}
          confirmBeforeLeaving
          variant="express-delivery"
        />
      </Box>

      {navigationIndex <= 1 && <NavigationCart allProducts={allProducts} />}
    </>
  );
}
