import { CartItemType } from "../../../../../providers/express-delivery-cart";
import { OrderItemType } from "../../../../../types/airgraft-express";

export function convertCartItemsToDeliveryOrderItems(
  cartitems: CartItemType[]
): OrderItemType[] {
  return cartitems.map(i => ({
    id: i.productVariantId,
    quantity: i.quantity,
    price: { amount: i.price, currencyCode: "USD" }
  }));
}
