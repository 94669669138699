import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function PhotoIdIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.5C3.17157 4.5 2.5 5.17157 2.5 6V18C2.5 18.8284 3.17157 19.5 4 19.5H20C20.8284 19.5 21.5 18.8284 21.5 18V6C21.5 5.17157 20.8284 4.5 20 4.5H4ZM4 6H5.5H18.5H20V7.5V16.5V18H18.5H5.5H4V16.5V7.5V6ZM6 8.25C5.58579 8.25 5.25 8.58579 5.25 9V15C5.25 15.4142 5.58579 15.75 6 15.75H11C11.4142 15.75 11.75 15.4142 11.75 15V9C11.75 8.58579 11.4142 8.25 11 8.25H6ZM6.75 14.25V9.75H10.25V14.25H6.75ZM13.25 9.00002C13.25 8.5858 13.5858 8.25002 14 8.25002H18C18.4142 8.25002 18.75 8.5858 18.75 9.00002C18.75 9.41423 18.4142 9.75002 18 9.75002H14C13.5858 9.75002 13.25 9.41423 13.25 9.00002ZM14 11.25C13.5858 11.25 13.25 11.5858 13.25 12C13.25 12.4142 13.5858 12.75 14 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
