import { NoSsr } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import NoSsrPlaceholder from "../../components/no-ssr-placeholder";
import ExpressDeliveryCheckout from "../../containers/express-delivery/Checkout";
import Page from "../../components/page";

type Props = {
  data: Queries.DeliveryCheckoutPageDataQuery;
};

export default function DeliveryCheckoutPage(props: Props) {
  if (!props.data.datoCmsWebPage) {
    return null;
  }
  return (
    <Page {...props.data.datoCmsWebPage} container="fluid">
      <NoSsr fallback={<NoSsrPlaceholder />}>
        <ExpressDeliveryCheckout
          allProducts={props.data.allDatoCmsProduct.nodes}
        />
      </NoSsr>
    </Page>
  );
}

export const query = graphql`
  query DeliveryCheckoutPageData($locale: String!) {
    datoCmsWebPage(path: { eq: "delivery/checkout" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
    allDatoCmsProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        ...DatoCmsProductItemFragment
      }
    }
  }
`;
