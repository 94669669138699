import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import LocalizedLink from "../../../../components/localized-link";
import { SHOP_DISCOVER_PATH } from "../../../../helpers/url";
import { useExpressDeliveryAvailabilitySelector } from "../../../../hooks/use-express-delivery-availability";
import useTranslate from "../../../../hooks/use-translate";
import { CartErrorType } from "../../../../providers/express-delivery-cart";
import CheckoutLayout from "./Layout";

export default function CartErrorPage({ error }: { error: CartErrorType }) {
  const t = useTranslate();
  const availableProvider = useExpressDeliveryAvailabilitySelector(
    c => c.availableProvider
  );
  return (
    <CheckoutLayout>
      <Box mb={4}>
        <Typography variant="h5">{t("cart.error.title")}</Typography>
      </Box>

      <Box mt={4}>
        {error === "EMPTY_CART" && (
          <Alert severity="error">{t("cart.empty")}</Alert>
        )}

        {error === "UNDER_MINIMUM_TOTAL" && (
          <Alert severity="error">
            {t("cart.error.minimumTotal", {
              price: availableProvider?.deliveryTotalMinimum
            })}
          </Alert>
        )}

        {error === "ONLY_REWARDS" && (
          <Box mb={2}>
            <Alert severity="error">
              {t("cart.error.onlyRewards", {
                minimumTotal: availableProvider?.deliveryTotalMinimum
              })}
            </Alert>
          </Box>
        )}

        {error === "NO_DELIVERY_ADDRESS" && (
          <Box mb={2}>
            <Alert severity="error">{t("cart.error.noAddress")}</Alert>
          </Box>
        )}

        {error === "UNAVAILABLE_DELIVERY_ADDRESS" && (
          <Box mb={2}>
            <Alert severity="error">{t("cart.error.unavailableAddress")}</Alert>
          </Box>
        )}

        {error === "OVER_THC_TOTAL_LIMIT" && (
          <Alert severity="error">
            {t("cart.error.maximumThc", {
              grams: availableProvider.purchaseLimitConcentratedWeight
            })}
          </Alert>
        )}
      </Box>

      <Box mt={4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          component={LocalizedLink}
          to={SHOP_DISCOVER_PATH}
        >
          {t("shop.backToShop")}
        </Button>
      </Box>
    </CheckoutLayout>
  );
}
