import { Box } from "@material-ui/core";
import { RouteComponentProps, useLocation } from "@reach/router";
import { navigate } from "gatsby-link";
import React from "react";
import { useAuthContextSelector } from "../../../../../hooks/use-auth";
import useFeatureFlag from "../../../../../hooks/use-feature-flag";
import useLocalizedLocationPath from "../../../../../hooks/use-localized-location-path";
import useTranslate from "../../../../../hooks/use-translate";
import Authenticate from "../../../../authentication/containers/Authenticate";
import CheckoutLayout from "../../components/Layout";

type Props = RouteComponentProps<Record<string, unknown>>;

export default function ExpressDeliveryCheckoutAuth({}: Props) {
  const t = useTranslate();
  const getLocalizedPath = useLocalizedLocationPath();

  const { pathname } = useLocation();
  const isSignIn = pathname.includes("/sign-in");

  // Redirect to chheckout after authentication
  const redirectAfterAuthentication = async (user, isNewUser: boolean) => {
    navigate(getLocalizedPath("/delivery/checkout"));
  };

  return (
    <CheckoutLayout>
      <Authenticate
        type={isSignIn ? "sign-in" : "register"}
        onAuthentication={redirectAfterAuthentication}
        labels={{
          register: { title: t("checkout.step1Auth.register.title") },
          signIn: {
            title: t("checkout.step1Auth.signIn.title")
          }
        }}
      />
    </CheckoutLayout>
  );
}
