import { CartItemType } from "../../../providers/express-delivery-cart";
import { ProductListItemType } from "../../../types/airgraft";

export function convertCartItemsToProductCardItems(
  cartItems: CartItemType[],
  allProducts: ReadonlyArray<ProductListItemType>
) {
  return cartItems.map(cartItem => {
    const product = allProducts.find(p =>
      p.variants.find(v => v.originalId === cartItem.productVariantId)
    );
    const productVariant = product?.variants.find(
      v => v.originalId === cartItem.productVariantId
    );
    return {
      title: productVariant?.title || cartItem.metadata?.name || "Product item",
      brand: {
        name: productVariant?.brand?.name || cartItem.metadata?.brand
      },
      image: productVariant.featuredImage?.url,
      quantity: cartItem.quantity,
      price: { amount: cartItem.price, currencyCode: "USD" },
      variantDetail: productVariant?.variantDetail
    };
  });
}
