import React, { useRef, useState } from "react";
import { Box, useTheme, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ALREADY_UPLOADED_PLACEHOLDER from "./already-uploaded-placeholder.png";
import useTranslate from "../../../../../../hooks/use-translate";

const DropzoneIcon = () => (
  <svg
    width={61 * 1.5}
    height={40 * 1.5}
    viewBox="0 0 61 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6665 8.5C26.8381 8.5 26.1665 9.17157 26.1665 10V30C26.1665 30.8284 26.8381 31.5 27.6665 31.5H54.3332C55.1616 31.5 55.8332 30.8284 55.8332 30V10C55.8332 9.17157 55.1616 8.5 54.3332 8.5H27.6665ZM27.6665 10H29.1665H52.8332H54.3332V11.5V28.5V30H52.8332H29.1665H27.6665V28.5V11.5V10ZM30.9998 14.25C30.5856 14.25 30.2498 14.5858 30.2498 15V25C30.2498 25.4142 30.5856 25.75 30.9998 25.75H39.3332C39.7474 25.75 40.0832 25.4142 40.0832 25V15C40.0832 14.5858 39.7474 14.25 39.3332 14.25H30.9998ZM31.7498 24.25V15.75H38.5832V24.25H31.7498ZM43.5832 15C43.5832 14.5858 43.919 14.25 44.3332 14.25H50.9998C51.414 14.25 51.7498 14.5858 51.7498 15C51.7498 15.4142 51.414 15.75 50.9998 15.75H44.3332C43.919 15.75 43.5832 15.4142 43.5832 15ZM44.3332 19.25C43.919 19.25 43.5832 19.5858 43.5832 20C43.5832 20.4142 43.919 20.75 44.3332 20.75H47.6665C48.0807 20.75 48.4165 20.4142 48.4165 20C48.4165 19.5858 48.0807 19.25 47.6665 19.25H44.3332Z"
      fill="#1E1E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6501 13.9996C10.6501 13.6406 10.3591 13.3496 10.0001 13.3496C9.64111 13.3496 9.3501 13.6406 9.3501 13.9996L9.3501 19.3496L4.0001 19.3496C3.64111 19.3496 3.3501 19.6406 3.3501 19.9996C3.3501 20.3586 3.64111 20.6496 4.0001 20.6496L9.3501 20.6496L9.3501 25.9996C9.3501 26.3586 9.64111 26.6496 10.0001 26.6496C10.3591 26.6496 10.6501 26.3586 10.6501 25.9996L10.6501 20.6496L16.0001 20.6496C16.3591 20.6496 16.6501 20.3586 16.6501 19.9996C16.6501 19.6406 16.3591 19.3496 16.0001 19.3496L10.6501 19.3496L10.6501 13.9996Z"
      fill="#1E1E1E"
    />
  </svg>
);

type Props = {
  error: boolean;
  hasAlreadyUploaded?: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const IdentificationImageFileInput = React.forwardRef(
  (
    { error, hasAlreadyUploaded, ...props }: Props,
    ref: { current: HTMLInputElement }
  ) => {
    const theme = useTheme();
    const t = useTranslate();

    const [imagePreviewUrl, setImagePreviewUrl] = useState<
      string | ArrayBuffer
    >();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // Get file image preview
      const reader = new FileReader();
      const file = event.target.files[0];

      if (file) {
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);

        if (props.onChange) {
          props.onChange(event);
        }
      }
    };

    const backgroundImageUrl = hasAlreadyUploaded
      ? ALREADY_UPLOADED_PLACEHOLDER
      : imagePreviewUrl;

    return (
      <Box
        width="100%"
        minHeight={{ xs: theme.spacing(25), md: theme.spacing(28) }}
        bgcolor={theme.palette.grey[100]}
        borderRadius="8px"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="stretch"
        border={`1px solid ${
          error ? theme.palette.error.main : theme.palette.grey[400]
        }`}
      >
        <Box
          width="100%"
          height="100%"
          flex={1}
          display="flex"
          flexDirection="center"
          justifyContent="center"
          alignItems="center"
          style={{
            // cursor: "pointer",
            backgroundImage: backgroundImageUrl
              ? `url("${backgroundImageUrl}")`
              : "none",
            backgroundColor: imagePreviewUrl
              ? theme.palette.common.white
              : theme.palette.grey[100],
            backgroundSize: hasAlreadyUploaded ? "cover" : "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        >
          {!imagePreviewUrl && !hasAlreadyUploaded && (
            <Box
              onClick={() => ref?.current?.click()}
              marginTop={2}
              style={{ cursor: "pointer" }}
            >
              <DropzoneIcon />
            </Box>
          )}
        </Box>

        <Box
          bgcolor={theme.palette.grey[100]}
          width="100%"
          paddingX={2}
          paddingY={1}
        >
          {!hasAlreadyUploaded && (
            <input
              {...props}
              data-testid="identification-image-input"
              ref={ref}
              type="file"
              onChange={handleFileChange}
              accept=".png,.jpg,.jpeg"
            />
          )}
          {hasAlreadyUploaded && (
            <Box display="flex" alignItems="center">
              <Typography variant="caption">
                {t("expressDelivery.idAlreadyUploaded")}
              </Typography>
              <Box display="flex" marginLeft={0.5}>
                <CheckCircleIcon fontSize="small" color="secondary" />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export default IdentificationImageFileInput;
