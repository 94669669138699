import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
  useTheme
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby-link";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { isEmail } from "validator";
import BirthdayInput, {
  BIRTHDAY_DATE_FORMAT,
  formatApiDateToFormBirthdayDate,
  formatFormBirthdayDateToApiDate,
  getLegalAgeValidator
} from "../../../../../components/birthday-input";
import GoogleMapsAutocompleteField from "../../../../../components/googlemaps-autocomplete-field";
import ArrowDown from "../../../../../components/icons/arrow-down";
import DeliveryTruckIcon from "../../../../../components/icons/delivery-truck";
import PhotoIdIcon from "../../../../../components/icons/photo-id";
import SmileIcon from "../../../../../components/icons/smile";
import LoadingOverlay from "../../../../../components/loading-overlay";
import LocalizedLink from "../../../../../components/localized-link";
import PhoneNumberInput, {
  validatePhoneNumber
} from "../../../../../components/phone-number-input";
import TitleGroup from "../../../../../components/title-group";
import { scrollToAnchor } from "../../../../../helpers/browser-behavior";
import {
  fetchDeliveryAddressByPlaceId,
  getDeliveryAddressLongName
} from "../../../../../helpers/express-delivery/address";
import {
  OrderHistoryResultType,
  previewOrder as requestPreviewOrder,
  submitOrder
} from "../../../../../helpers/express-delivery/order";
import {
  fetchAvailableProviders,
  getProviderDeliveryOrPickupTimeframes
} from "../../../../../helpers/express-delivery/provider";
import { getOrderDetailsPath } from "../../../../../helpers/path";
import { formatPhoneNumberForApi } from "../../../../../helpers/phone-number";
import useAuth from "../../../../../hooks/use-auth";
import useExpressDeliveryAvailability from "../../../../../hooks/use-express-delivery-availability";
import useExpressDeliveryCart from "../../../../../hooks/use-express-delivery-cart";
import useFeatureFlag from "../../../../../hooks/use-feature-flag";
import useLocalizedLocationPath from "../../../../../hooks/use-localized-location-path";
import useTranslate from "../../../../../hooks/use-translate";
import {
  analyticsIdentifyDeliveryAddress,
  analyticsTrackBeginCheckout,
  analyticsTrackPurchase
} from "../../../../../services/analytics";
import { reportSentryClientError } from "../../../../../services/sentry";
import {
  hasAlreadyUploadedIdentificationImage,
  uploadUserIdentificationImage
} from "../../../../../services/user";
import {
  GoogleMapsAutocompletePlaceType,
  ProductListItemType
} from "../../../../../types/airgraft";
import { OrderType } from "../../../../../types/airgraft-express";
import ExpressDeliveryOrderSummary from "../../../OrderSummary";
import ExpressDeliveryProviderCard from "../../../ProviderCard";
import CartErrorPage from "../../components/CartErrorPage";
import CheckoutLayout from "../../components/Layout";
import { convertCartItemsToProductCardItems } from "../../utils";
import IdentificationImageFileInput from "./IdentificationImageFileInput";
import { convertCartItemsToDeliveryOrderItems } from "./utils";

export type DeliveryFormDataType = {
  givenName: string;
  familyName: string;
  birthday: string;
  address: GoogleMapsAutocompletePlaceType;
  addressTwo?: string;
  phoneNumber?: string;
  email?: string;
  deliveryTime?: string;
  deliveryInstructions?: string;
  identificationImage?: File;
};

type Props = RouteComponentProps<Record<string, unknown>> & {
  allProducts: ProductListItemType[];
};

/**
 * Express Delivery Checkout page/form
 */
export default function ExpressDeliveryCheckout({ allProducts }: Props) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const t = useTranslate();
  const { user, refetchUserInfo } = useAuth();
  const getLocalizedPath = useLocalizedLocationPath();

  const {
    address: originalAddress,
    availableProvider,
    isLoadingAvailableProvider,
    isLoadingAvailableProducts,
    availableProducts
  } = useExpressDeliveryAvailability();

  const cart = useExpressDeliveryCart();

  // On load: Track analytics checkout
  useEffect(() => {
    analyticsTrackBeginCheckout(cart);
  }, []);

  // Only checkout cart items that are available with current provider
  const availableCartItems = (cart?.items || []).filter(item => {
    return (availableProducts || []).find(
      ap => ap.productVariantId === item.productVariantId
    );
  });

  // Some fields are not available for specific providers.
  const isIdentificationImageEnabled = availableProvider?.apiType !== "amuse";
  const isDeliveryTimeEnabled = true;
  const isDeliveryInstuctionsEnabled = availableProvider?.apiType !== "amuse";
  const [isPhoneNumberFieldEnabled, setPhoneNumberFieldEnabled] = useState(
    // Show phone number field if user didnt sign up with phone number
    !!user?.airgraftExpressData?.phoneNumber || !!user?.phoneNumber
  );

  // Use address previously provided inside the shop
  let defaultAddress = null;
  if (originalAddress) {
    defaultAddress = {
      place_id: originalAddress?.placeId,
      description: getDeliveryAddressLongName(originalAddress)
    };
  }

  // Prefill address after loading availability address
  useEffect(() => {
    if (originalAddress) {
      setValue(
        "address",
        {
          place_id: originalAddress?.placeId,
          description: getDeliveryAddressLongName(originalAddress)
        },
        { shouldValidate: true }
      );
    }
  }, [originalAddress]);

  // Form config
  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    setError,
    errors,
    formState,
    trigger,
    control,
    watch
  } = useForm<DeliveryFormDataType>({
    shouldUnregister: false,
    mode: "onBlur",
    defaultValues: {
      address: defaultAddress,
      addressTwo: user?.airgraftExpressData?.deliveryAddress?.addressTwo,
      phoneNumber: user?.airgraftExpressData?.phoneNumber || user?.phoneNumber,
      email: user?.email || user?.airgraftExpressData?.email,
      givenName: user?.givenName,
      familyName: user?.familyName,
      birthday: user?.airgraftExpressData?.birthday
        ? formatApiDateToFormBirthdayDate(user.airgraftExpressData.birthday)
        : null
    }
  });
  const formAddressValue = watch("address");
  const [orderError, setOrderError] = useState<string>(null); // Order error from server
  const [isRedirecting, setRedirecting] = useState(false);

  // On mount: Register custom "address" field
  useEffect(() => {
    if (!availableProvider) return; // Wait until available provider is available to register address field. Else validate wont have access.

    // Manually register the address field that use GoogleAutocompleteField
    register(
      { name: "address" },
      {
        required: t("error.required"),
        validate: async (place: GoogleMapsAutocompletePlaceType) => {
          const providers = await fetchAvailableProviders(place.place_id);
          if (!providers || providers.length === 0) {
            return "Orders are not available for this address, please try another one.";
          }
          if (providers[0]?.id !== availableProvider?.id) {
            return `Orders are not available for this address with the delivering dispensary ${availableProvider.publicName}, please try another one.`;
          }
          return true;
        }
      }
    );
    return () => unregister(["address"]);
  }, [register, availableProvider?.id]);

  // Manually update "address" form value on autocomplete change
  const handleAddressAutocompleteChange = async (
    event,
    place: GoogleMapsAutocompletePlaceType
  ) => {
    setValue("address", place);
    trigger("address");
  };

  // On Form Submit: Submit order using Netlify functions /delivery-order-request endpoint
  const handleDeliveryInfoFormSubmit = async (data: DeliveryFormDataType) => {
    setOrderError(null);

    const deliveryAddress = await fetchDeliveryAddressByPlaceId(
      data.address.place_id,
      data.addressTwo
    );

    if (data.deliveryInstructions && data.deliveryInstructions.trim() !== "") {
      deliveryAddress.deliveryInstructions = data.deliveryInstructions;
    }

    // Calculate delivery time end
    const deliveryTimeDayOfTheWeek = moment
      .weekdaysShort(
        moment
          .tz(data.deliveryTime, moment.ISO_8601, availableProvider.timezone)
          .weekday()
      )
      .toLowerCase();
    let deliveryTimeframeMinutes = 60;
    if (availableProvider.deliveryTimes[deliveryTimeDayOfTheWeek]?.timeframe) {
      deliveryTimeframeMinutes =
        availableProvider.deliveryTimes[deliveryTimeDayOfTheWeek]?.timeframe;
    }
    const deliveryTimeEnd = moment
      .tz(data.deliveryTime, moment.ISO_8601, availableProvider.timezone)
      .add(deliveryTimeframeMinutes, "minutes")
      .format();

    // Submit order
    try {
      const order = await submitOrder({
        providerId: availableProvider.id,
        deliveryAddress,
        givenName: data.givenName,
        familyName: data.familyName,
        phoneNumber: isPhoneNumberFieldEnabled
          ? formatPhoneNumberForApi(data.phoneNumber)
          : user.phoneNumber,
        email: data.email,
        birthday: formatFormBirthdayDateToApiDate(data.birthday),
        cartItems: convertCartItemsToDeliveryOrderItems(availableCartItems),
        deliveryTime: data.deliveryTime,
        deliveryTimeEnd: deliveryTimeEnd
      });

      // Upload identification image if needed
      if (isIdentificationImageEnabled && data.identificationImage) {
        try {
          await uploadUserIdentificationImage(
            availableProvider.id,
            data.identificationImage
          );
        } catch (e) {
          reportSentryClientError(e);
        }
      }

      // Force loading while redirecitng to order details
      setRedirecting(true);

      try {
        // Track analytics purchase
        analyticsTrackPurchase({ cart, order, deliveryFormData: data });

        // Re-identify user analytics address
        analyticsIdentifyDeliveryAddress(deliveryAddress);
      } catch (e) {
        reportSentryClientError(e);
      }

      try {
        // Clear cart
        cart.emptyCart();

        // Cache order locally to make order confirmation load faster
        if (order.orderLogId) {
          queryClient.setQueryData(["order", order.orderLogId], order);
          queryClient.setQueryData(
            "orders",
            (oldData: OrderHistoryResultType) => ({
              orders: oldData?.orders ? [order, ...oldData.orders] : [order],
              lastPage: true
            })
          );
        }
      } catch (e) {
        reportSentryClientError(e);
      }

      try {
        // Refetch user.airgraftExpressData
        await refetchUserInfo();
      } catch (e) {
        reportSentryClientError(e);
      }

      // Redirect the order confirmation
      navigate(getLocalizedPath(getOrderDetailsPath(order)));
    } catch (e) {
      if (e.errorReason === "out-of-range") {
        setError("address", {
          type: e.errorReason,
          message:
            "Orders are not available for this address, please try another one."
        });
      } else if (e.errorReason === "phoneNumber") {
        setError("phoneNumber", {
          type: e.errorReason,
          message: "Invalid number, please input a valid US phone number."
        });
        setPhoneNumberFieldEnabled(true);
      } else if (e.errorReason === "email") {
        setError("email", {
          type: e.errorReason,
          message: "Invalid email, please use a valid email address."
        });
      } else if (e.errorReason === "birthday") {
        setError("birthday", {
          type: e.errorReason,
          message: "Invalid birthday, please input a valid birthday."
        });
      } else if (e.errorReason === "out-of-stock") {
        setOrderError(
          "The delivering dispensary is temporarily out of stock. Restock is on its way, please try again later."
        );
      } else {
        setOrderError(
          "Sorry, something went wrong there. We're working on it and we'll get it fixed as soon as we can. We keep track of these errors, but feel free to contact us at support@airgraft.com if refeshing doesn't fix things."
        );
      }

      setTimeout(() => scrollToAnchor("#order-error-message", 0), 100); // Autoscroll to error
      return;
    }
  };

  // Validate delivery time on submit: Make sure timeframe is still available in case user took too long to submit
  const validateDeliveryTime = (value: string) => {
    const timeframes = getProviderDeliveryOrPickupTimeframes(
      availableProvider,
      "delivery"
    );
    const isTimeframeStillAvailable = !!timeframes.find(t => t.value === value);
    if (!isTimeframeStillAvailable) {
      return t("checkout.deliveryTime.error");
    }
    return true;
  };

  // On mount: Force scroll top
  useEffect(() => window.scroll({ top: 0 }), []);

  // On form validation error: Autoscroll to field with error after validation fails
  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      const fieldRef = errors[Object.keys(errors)[0]]?.ref;
      if (fieldRef?.scrollIntoView) {
        fieldRef.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (fieldRef?.focus) {
        fieldRef?.focus();
      }
    }
  }, [errors]);

  // Delivery times dropdown options
  const availableDeliveryTimeframes = availableProvider
    ? getProviderDeliveryOrPickupTimeframes(availableProvider, "delivery")
    : [];

  // Image upload ID
  const [hasAlreadyUploadedImage, setAlreadyUploadedImage] = useState<
    "loading" | true | false
  >("loading");

  // On mount: Fetch if user has already uploaded a identification image for current provider
  useEffect(() => {
    if (!isIdentificationImageEnabled || !availableProvider) return;

    async function updateHasAlreadyUploadedImage() {
      if (!user) {
        setAlreadyUploadedImage(false);
      } else {
        const hasAlreadyUploaded = await hasAlreadyUploadedIdentificationImage(
          availableProvider.id
        );
        setAlreadyUploadedImage(hasAlreadyUploaded);
      }
    }
    updateHasAlreadyUploadedImage();
  }, [user, availableProvider?.id]);

  // On identificationImage validation error: Scroll to file input
  const identificationImageRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (
      Object.keys(errors).length === 1 &&
      errors.identificationImage &&
      identificationImageRef.current
    ) {
      identificationImageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }, [errors, identificationImageRef]);

  // After loading "hasAlreadyUploadedImage": Register image field
  useEffect(() => {
    if (!isIdentificationImageEnabled) return;

    // Manually register the id image file field that use <input type="file" />
    register(
      { name: "identificationImage" },
      {
        validate: async (file: File) => {
          // If user already has a image, dont validate or make it required.
          if (hasAlreadyUploadedImage === true && !file) {
            return true;
          }
          if (!file) {
            return t("error.required");
          }
          const fileSizeMb = file.size / 1024 / 1024;
          if (fileSizeMb > 10) {
            return t("checkout.identificationImage.error.imageTooBig", {
              size: "10 MB"
            });
          }
          return true;
        }
      }
    );

    return () => unregister("identificationImage");
  }, [register, hasAlreadyUploadedImage, isIdentificationImageEnabled]);

  // Preview order: Make a request to /delivery-order-request with preview=true to get the Order tax, delivery fee and total price.
  const [isLoadingPreviewOrder, setLoadingPreviewOrder] =
    useState<boolean>(false);
  const [previewOrder, setPreviewOrder] = useState<OrderType>(null);
  const [previewOrderError, setPreviewOrderError] = useState<Error>(null);
  useEffect(() => {
    if (
      isLoadingPreviewOrder ||
      !availableProvider ||
      availableCartItems.length === 0
    ) {
      return;
    }
    if (!formAddressValue) {
      setLoadingPreviewOrder(false);
      return;
    }

    async function fetchPreviewOrder() {
      setPreviewOrderError(null);
      setLoadingPreviewOrder(true);
      const deliveryAddress = await fetchDeliveryAddressByPlaceId(
        formAddressValue.place_id
      );
      try {
        const previewOrder = await requestPreviewOrder({
          providerId: availableProvider.id,
          // Using fake order data to get order price preview
          givenName: "Preview",
          familyName: "Preview",
          phoneNumber: "213-341-2334",
          birthday: "1970-01-01",
          deliveryAddress,
          cartItems: convertCartItemsToDeliveryOrderItems(availableCartItems)
        });

        setPreviewOrder(previewOrder);
        setLoadingPreviewOrder(false);
      } catch (e) {
        setPreviewOrderError(e);
        setLoadingPreviewOrder(false);
      }
    }
    fetchPreviewOrder();
  }, [
    formAddressValue?.place_id,
    availableProvider?.id,
    availableCartItems.length
  ]);

  const cartSubTotalPrice = {
    amount: cart.cartTotal,
    currencyCode: "USD"
  };

  const showLoading =
    !user ||
    isLoadingAvailableProvider ||
    isLoadingAvailableProducts ||
    isRedirecting;

  if (!showLoading && cart.cartError) {
    return <CartErrorPage error={cart.cartError} />;
  }

  return (
    <CheckoutLayout showLoading={showLoading}>
      <form
        onSubmit={handleSubmit(handleDeliveryInfoFormSubmit)}
        style={{
          width: "100%"
        }}
      >
        {/* Header: Title */}
        <Box mb={{ xs: 2, md: 4 }}>
          <Typography variant="h4" component="h1">
            Review your order
          </Typography>
        </Box>

        {/* Cart items */}
        <TitleGroup title="Your order" mb={4}>
          <ExpressDeliveryOrderSummary
            productCardItems={convertCartItemsToProductCardItems(
              availableCartItems,
              allProducts
            )}
            subTotal={previewOrder?.subTotal || cartSubTotalPrice}
            deliveryFeeTotal={previewOrder?.deliveryFeeTotal}
            taxTotal={previewOrder?.taxTotal}
            total={previewOrderError ? cartSubTotalPrice : previewOrder?.total}
            totalSuffix={previewOrderError ? " + taxes" : null}
            previewLoading={isLoadingPreviewOrder}
            mt={1}
          />

          {previewOrderError && (
            <Box mt={2}>
              <Alert severity="warning">
                Delivery fee and tax estimates unavailable at the moment. Final
                total will be shown once the order is placed.
              </Alert>
            </Box>
          )}
        </TitleGroup>

        {/* Delivery Payment methods */}
        {availableProvider?.availablePayment && (
          <TitleGroup title="Payment Options" mb={4}>
            <Typography>{availableProvider.availablePayment}</Typography>
          </TitleGroup>
        )}

        {/* Delivery provider */}
        {availableProvider && (
          <TitleGroup title="Delivering Dispensary" mb={4}>
            <ExpressDeliveryProviderCard provider={availableProvider} />
          </TitleGroup>
        )}

        {/* Delivery Info form */}
        <TitleGroup title="Delivery Info" alignItems="stretch" mb={4}>
          {/* First & Last name */}
          <Box display="flex" flexDirection="column" alignItems="stretch">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              mb={2}
            >
              <Box flex={1} mr={1}>
                <TextField
                  variant="outlined"
                  id="givenName"
                  name="givenName"
                  label={"First name*"}
                  placeholder="First name"
                  fullWidth
                  inputRef={register({
                    required: t("error.required"),
                    maxLength: "255"
                  })}
                  error={!!errors.givenName}
                  helperText={errors.givenName?.message}
                  disabled={formState.isSubmitting}
                  autoComplete="given-name"
                />
              </Box>
              <Box flex={1} ml={1}>
                <TextField
                  variant="outlined"
                  id="familyName"
                  name="familyName"
                  label={"Last name*"}
                  placeholder="Last name"
                  fullWidth
                  inputRef={register({
                    required: t("error.required"),
                    maxLength: "255"
                  })}
                  error={!!errors.familyName}
                  helperText={errors.familyName?.message}
                  disabled={formState.isSubmitting}
                  autoComplete="family-name"
                />
              </Box>
            </Box>

            {/* Phone number (Only if user doesnt have one) */}
            <Box mb={2} display={isPhoneNumberFieldEnabled ? "block" : "none"}>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                variant="outlined"
                label={`${t("phoneNumber")}*`}
                placeholder="(000) 000-0000"
                fullWidth
                inputRef={register({
                  required: t("error.required"),
                  validate: validatePhoneNumber
                })}
                InputProps={{
                  inputComponent: PhoneNumberInput
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                disabled={formState.isSubmitting}
                autoComplete="tel"
              />
            </Box>

            {/* Email */}
            <Box mb={2}>
              <TextField
                variant="outlined"
                id="email"
                name="email"
                autoComplete="email"
                fullWidth
                label={`${t("email")}*`}
                placeholder={t("authentication.emailPlaceholder")}
                inputRef={register({
                  validate: (value: string) => {
                    if (!value || value === "") {
                      return t("error.required");
                    }
                    if (!isEmail(value)) {
                      return t("error.invalidEmail");
                    }
                    return true;
                  }
                })}
                error={!!errors.email}
                helperText={
                  errors.email && errors.email.type !== "required"
                    ? errors.email?.message
                    : null
                }
                disabled={formState.isSubmitting}
              />
            </Box>

            {/* Date of birth */}
            <Box mb={2}>
              <TextField
                id="birthday"
                name="birthday"
                variant="outlined"
                label={`${t("dateOfBirth")}*`}
                placeholder={BIRTHDAY_DATE_FORMAT}
                fullWidth
                inputRef={register({
                  required: t("error.required"),
                  validate: getLegalAgeValidator(21, t)
                })}
                InputProps={{
                  inputComponent: BirthdayInput
                }}
                error={!!errors.birthday}
                helperText={errors.birthday?.message}
                disabled={formState.isSubmitting}
              />
            </Box>

            {/* Address (Google autocomplete) */}
            <Box mb={2}>
              <GoogleMapsAutocompleteField
                label="Delivery Address*"
                onChange={handleAddressAutocompleteChange}
                error={!!errors.address}
                disabled={formState.isSubmitting}
                defaultValue={defaultAddress}
                placeholder="Enter a street address"
              />
              {errors.address && (
                <FormHelperText error>
                  {(errors.address as any).message}
                </FormHelperText>
              )}
            </Box>

            {/* Address Line 2  (Apt, Suite) */}
            <Box mb={2}>
              <TextField
                variant="outlined"
                id="addressTwo"
                name="addressTwo"
                label={"Apartment, suite, etc. (Optional)"}
                placeholder="Apartment, suite.."
                fullWidth
                inputRef={register}
                error={!!errors.addressTwo}
                disabled={formState.isSubmitting}
                autoComplete="shipping address-line2"
              />
            </Box>

            {/* Delivery instructions */}
            {isDeliveryInstuctionsEnabled && (
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  name="deliveryInstructions"
                  label="Delivery Instructions"
                  placeholder="Optional"
                  fullWidth
                  inputRef={register({ maxLength: 1000 })}
                  rowsMax={10}
                  error={!!errors.deliveryInstructions}
                  multiline
                  disabled={formState.isSubmitting}
                />
              </Box>
            )}
          </Box>
        </TitleGroup>

        {/* Identification image (File upload) */}
        <TitleGroup title="ID Check" mb={5}>
          {isIdentificationImageEnabled && (
            <Box width="100%">
              {hasAlreadyUploadedImage === "loading" && (
                <Box display="flex" justifyContent="center">
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={theme.spacing(25)}
                    style={{ borderRadius: "8px" }}
                  />
                </Box>
              )}
              {hasAlreadyUploadedImage !== "loading" && (
                <>
                  <Box display="flex" justifyContent="flex-start">
                    <IdentificationImageFileInput
                      name="identificationImage"
                      onChange={event =>
                        setValue("identificationImage", event.target.files[0], {
                          shouldValidate: true
                        })
                      }
                      error={!!errors.identificationImage}
                      hasAlreadyUploaded={hasAlreadyUploadedImage}
                      ref={identificationImageRef}
                      disabled={formState.isSubmitting}
                    />
                  </Box>

                  {errors.identificationImage && (
                    <FormHelperText error>
                      {(errors.identificationImage as any).message}
                    </FormHelperText>
                  )}

                  {hasAlreadyUploadedImage !== true && (
                    <>
                      <Box mt={1.5}>
                        <Typography variant="body2">
                          Verify you are over 21, please upload a photo of
                          passport or driver license.
                        </Typography>
                      </Box>

                      <Box
                        mt={1.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Box
                          mr={1}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <SmileIcon />
                        </Box>
                        <Typography variant="caption">
                          The file you upload will be securely submitted to the
                          dispensary. Airgraft does not store your ID at
                          anytime.
                        </Typography>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          )}

          {!isIdentificationImageEnabled && (
            <Box
              bgcolor="common.white"
              borderRadius="8px"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
              p={{ xs: 2, md: 2.5 }}
              display="flex"
              alignItems="center"
            >
              <Box mr={2}>
                <PhotoIdIcon fontSize="large" />
              </Box>
              <Typography variant="body2">
                The delivering dispensary will send you an SMS to complete your
                ID Check. Your order won’t be fulfilled until you submit your
                details.
              </Typography>
            </Box>
          )}
        </TitleGroup>

        {/* Delivery time */}
        <TitleGroup title="Scheduling your order" mb={6}>
          {isDeliveryTimeEnabled && (
            <>
              <Controller
                labelId="delivery-time"
                name="deliveryTime"
                rules={{
                  required: t("error.required"),
                  validate: validateDeliveryTime
                }}
                control={control}
                error={!!errors.deliveryTime}
                defaultValue=""
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    select
                    label="Select a delivery time*"
                    variant="outlined"
                    name={name}
                    inputRef={ref}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.deliveryTime}
                    data-testid="delivery-time"
                    SelectProps={{
                      IconComponent: () => (
                        <ArrowDown
                          style={{
                            position: `absolute`,
                            right: theme.spacing(2)
                          }}
                        />
                      )
                    }}
                  >
                    {availableDeliveryTimeframes &&
                      availableDeliveryTimeframes.length > 0 && (
                        <MenuItem value="" disabled>
                          Select a delivery time
                        </MenuItem>
                      )}
                    {availableDeliveryTimeframes &&
                      availableDeliveryTimeframes.map((timeframe, index) => (
                        <MenuItem
                          key={timeframe.value}
                          value={timeframe.value}
                          data-testid={
                            index === 0 ? "delivery-time-item" : undefined
                          }
                        >
                          {timeframe.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
              {errors.deliveryTime && (
                <FormHelperText error>
                  {(errors.deliveryTime as any).message}
                </FormHelperText>
              )}
            </>
          )}

          {!isDeliveryTimeEnabled && (
            <Box
              bgcolor="common.white"
              borderRadius="8px"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
              p={{ xs: 2, md: 2.5 }}
              display="flex"
              alignItems="center"
            >
              <Box mr={2}>
                <DeliveryTruckIcon fontSize="large" />
              </Box>
              <Typography variant="body2">
                The delivering dispensary will send you an SMS for you to
                confirm your preferred delivery time.
              </Typography>
            </Box>
          )}
        </TitleGroup>

        {/* Cart errors */}
        {cart.cartError && (
          <Box mb={4}>
            <Alert severity="error">{cart.cartError}</Alert>
          </Box>
        )}

        {/* Submit order */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          className="express-delivery"
          disabled={formState.isSubmitting || !!cart.cartError}
        >
          Submit order
        </Button>

        {/* General error */}
        {orderError && (
          <Box mt={2} id="order-error-message">
            <Alert id="#error" severity="error">
              {orderError}
            </Alert>
          </Box>
        )}

        {/* Back to shop button */}
        {!orderError && (
          <Box mt={1.5}>
            <Button
              variant="contained"
              fullWidth
              component={LocalizedLink}
              to="/shop"
            >
              {t("shop.backToShop")}
            </Button>
          </Box>
        )}

        {/* Loading overlay when submitting order */}
        {formState.isSubmitting && (
          <LoadingOverlay
            texts={[
              "We are sending your order to the delivering dispensary.",
              "Things are moving, stay with us"
            ]}
            timings={[0, 6]}
          />
        )}
      </form>
    </CheckoutLayout>
  );
}
