import { useEffect, useState } from "react";

export function useProgressiveLoadingText(
  timings: number[],
  strings: string[]
): string {
  if (timings.length !== strings.length) {
    throw new Error(
      `You passed ${timings.length} times and ${strings.length} - there should be the same number of each.`
    );
  }

  const defaultText = timings.length > 0 && timings[0] === 0 ? strings[0] : "";
  const [text, setText] = useState<string>(defaultText);
  const [timers, setTimers] = useState<number[]>([]);

  useEffect(() => {
    timings.forEach((delay: number, index: number) => {
      if (delay > 0) {
        const timer: number = window.setTimeout(
          () => setText(strings[index]),
          delay * 1000
        );
        setTimers(oldTimers => [...oldTimers, timer]);
      }
    });
    return () => {
      timers.forEach(timer => window.clearTimeout(timer));
    };
  }, [timings, strings]);

  return text;
}
