import { Box, Hidden, Typography, useTheme } from "@material-ui/core";
import React, { useRef } from "react";
import { useToggle } from "react-use";
import ChevronDownIcon from "../../../../components/icons/chevron-down";
import ShopingCartIcon from "../../../../components/icons/shopping-cart";
import { formatPrice } from "../../../../helpers/price";
import { useExpressDeliveryAvailabilitySelector } from "../../../../hooks/use-express-delivery-availability";
import useExpressDeliveryCart from "../../../../hooks/use-express-delivery-cart";
import { ProductListItemType } from "../../../../types/airgraft";
import HeaderPanelBase from "../../../header/PanelBase";
import ProductsCard from "../../ProductsCard";
import { convertCartItemsToProductCardItems } from "../utils";

type Props = {
  allProducts?: ReadonlyArray<ProductListItemType>;
};

export default function NavigationCart({ allProducts }: Props) {
  const theme = useTheme();
  const cart = useExpressDeliveryCart();
  const availableProvider = useExpressDeliveryAvailabilitySelector(
    c => c.availableProvider
  );
  const [isOpen, toggleOpen] = useToggle(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  return (
    <Hidden mdUp>
      {/* Checkout cart header */}
      <Box
        position="fixed"
        top="var(--header-height)"
        left={0}
        zIndex={theme.zIndex.appBar + 1}
        width="100%"
        height="var(--header-height)"
        bgcolor="common.white"
        display="flex"
        alignItems="center"
        paddingX={2.5}
        role="button"
        onClick={toggleOpen}
        ref={buttonRef}
        style={{ cursor: "pointer" }}
      >
        <Box flex={1} display="flex" alignItems={"center"}>
          <ShopingCartIcon />
          <Box marginX={1}>
            <Typography variant="body2">Show order summary</Typography>
          </Box>
          <Box
            bgcolor={"expressDelivery"}
            width="6px"
            height="6px"
            borderRadius="6px"
            mr={1}
            flexShrink={0}
          />
          {cart.cartTotal > 0 && (
            <Typography variant="body2" className="bold">
              {formatPrice({ amount: cart.cartTotal })}
            </Typography>
          )}
        </Box>

        <ChevronDownIcon
          fontSize="small"
          color="secondary"
          className="chrevron-down"
        />
      </Box>

      {/* Checkout Cart panel */}
      {isOpen && (
        <HeaderPanelBase
          open={isOpen}
          onClose={() => toggleOpen(false)}
          anchorEl={buttonRef as any}
          disableMobileHeaderOverModal
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`1px solid ${theme.palette.grey[300]}`}
            paddingTop={2}
            paddingBottom={2.5}
            paddingX={1.5}
            maxHeight="50px"
          >
            <Typography variant="h6">Your Order</Typography>
          </Box>

          <ProductsCard
            products={convertCartItemsToProductCardItems(
              cart.items,
              allProducts
            )}
            boxShadow="none"
            borderLeft="none"
          />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            borderTop={`1px solid ${theme.palette.grey[300]}`}
            paddingX={1.5}
            paddingY={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Typography variant="body2">Shipping</Typography>
              <Typography
                variant="body2"
                className="bold"
                style={{ color: theme.palette.expressDelivery }}
              >
                {availableProvider?.deliveryFee || "FREE"}
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" className="bold">{`Subtotal (${
                cart.items.length
              } item${cart.items.length > 1 ? "s" : ""})`}</Typography>

              <Typography variant="h6">
                {formatPrice({ amount: cart.cartTotal })}
              </Typography>
            </Box>
          </Box>
        </HeaderPanelBase>
      )}

      <Box height="var(--header-height)" />
    </Hidden>
  );
}
